import Link from "next/link";

export default function Custom500() {
  return (
    <main className="h-screen bg-gray-500 flex justify-center items-center">
      <section className="flex-grow flex pt-20 justify-center pb-32 lg:pt-10 flex-col">
        <div className="flex justify-center">
          <h3 className="lg:text-4xl text-5xl text-center font-extrabold">
            This is quite embarrassing
          </h3>
        </div>

        <div className="hidden md:flex flex-row items-start mt-16">
          <div className="w-full">
            <img
              src="/images/leftline404.svg"
              alt="left line 404"
              className="w-full"
            />
          </div>
          <div
            className="flex flex-row justify-between lg:-mt-8 "
            style={{ width: "40%" }}
          >
            <img src="/images/left404.svg" alt="" />
            <img src="/images/middle404.svg" alt="" />
            <img src="/images/right404.svg" alt="" />
          </div>
          <div className="w-full">
            <img
              src="/images/rightline404.svg"
              alt="right line 404"
              className="w-full"
            />
          </div>
        </div>

        <nav className="md:-mt-10">
          <h5 className="text-center text-xl lg:text-2xl mt-4 xl:-mt-20">
            Something went wrong, but don’t <br /> worry, we’re fixing it now.
          </h5>
          <div className="flex mt-10">
            <Link href="/">
              <button className="p-2 pr-4 pl-4 font-semibold m-auto lg-w-4/12 text-white  bg-primary border-2 border-primary rounded-md">
                GO TO HOMEPAGE
              </button>
            </Link>
          </div>
        </nav>
      </section>
    </main>
  );
}
